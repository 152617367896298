<template>
  <b-col
    lg="12"
    class="d-flex align-items-center"
  >
    <b-col>
      <b-card-title
        title-tag="h2"
        class="font-weight-bold my-2"
      >
        Google authenticator code
      </b-card-title>
      <validation-observer ref="twofaValidation">
        <b-form
          class="mb-2"
          @submit.prevent
        >
          <validation-provider
            #default="{ errors }"
            name="twofa"
            rules="required|digits:6"
          >
            <b-form-group>
              <b-form-input
                v-model="twofaCode"
                placeholder="Google authenticator code"
                name="twofa"
                :state="errors.length ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="requestInProgress"
            class="mt-1"
            @click="validationForm"
          >
            Login
          </b-button>
        </b-form>
      </validation-observer>
    </b-col>
  </b-col>
</template>

<script>
import {
  BButton, BCardTitle, BCol, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'
import validationError from '@/mixins/validationError'
import waitRequest from '@/mixins/waitRequest'
// eslint-disable-next-line
import { required, digits } from '@validations'

export default {
  name: 'TwofaModalContent',
  components: {
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BForm,
    BCol,
    BCardTitle,
  },
  mixins: [validationError, waitRequest],
  props: {
    rememberMe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      twofaCode: '',
    }
  },
  mounted() {
    this.registerValidator(this.$refs.twofaValidation)
  },
  methods: {
    ...mapActions({
      loginWithTwofa: 'auth/loginWithTwofa',
      fetchProfile: 'auth/fetchProfile',
      fetchUserRoles: 'users/fetchUserRoles',
    }),
    handleRedirect(role) {
      if (role === 'Content') {
        this.$router.push('/team')
      } else {
        this.$router.push('/')
      }
    },

    async validationForm() {
      if (this.requestInProgress) return

      const isValid = await this.checkIsValidForm()
      console.log(isValid)
      if (!isValid) return

      return this.waitRequest(() => this.loginWithTwofa({
        code: this.twofaCode,
        rememberMe: this.rememberMe,
      })
        .then(() => {
          this.fetchProfile()
          this.fetchUserRoles().then(response => {
            const role = response.data[0].name
            this.handleRedirect(role)
          })
        })
        .catch(this.checkErrors))
    },
  },
}
</script>

<style scoped lang="scss"></style>
