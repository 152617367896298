<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Fintopio! 👋
          </b-card-title>

          <b-card-text class="mb-2">
            Please login to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length ? false : null"
                    name="email"
                    placeholder="john@example.com"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>

                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="requestInProgress"
                @click="validationForm"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal
      v-model="isModalOpened"
      centered
      hide-footer
    >
      <TwofaModalContent :remember-me="status" />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import validationError from '@/mixins/validationError'
import waitRequest from '@/mixins/waitRequest'
import { isTokenExpires } from '@/tools/util'
import TwofaModalContent from '@/components/TwofaModalContent.vue'

export default {
  components: {
    TwofaModalContent,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, validationError, waitRequest],
  data() {
    return {
      status: false,
      password: '',
      userEmail: '',
      code: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      isModalOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      admin: 'auth/admin',
      token: 'auth/token',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    isTokenExpires,
    ...mapActions({
      login: 'auth/login',
      fetchUserRoles: 'users/fetchUserRoles',
      fetchProfile: 'auth/fetchProfile',
    }),

    initState() {
      this.registerValidator(this.$refs.loginValidation)

      if (this.token) {
        const role = this.admin?.roles?.at(0)?.name
        this.handleRedirect(role)
      }
    },

    handleRedirect(role) {
      if (role === 'Content') {
        this.$router.push('/team')
      } else {
        this.$router.push('/')
      }
    },

    async validationForm() {
      if (this.requestInProgress) return

      const isValid = await this.checkIsValidForm()
      if (!isValid) return

      return this.waitRequest(() => this.login({
        email: this.userEmail,
        password: this.password,
        rememberMe: this.status,
      })
        .then(res => {
          if (res.data.need2fa) {
            this.isModalOpened = true
            return
          }
          this.fetchProfile()
          this.fetchUserRoles().then(response => {
            const role = response.data[0].name
            this.handleRedirect(role)
          })
        })
        .catch(this.checkErrors))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
